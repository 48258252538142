import React from "react"
import { Box, Image } from "theme-ui"
import styled from "styled-components"
import Layout from "../components/Layout"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"

import useStories from "../hooks/useStories"
import MultipleLinksSelector from "../components/Links/MultipleLinksSelector"
import TextContent from "../components/TextContent"
import cloudinaryString from "../helpers/cloudinaryString"

const golfImages = [
  "https://res.cloudinary.com/gonation/image/upload/v1689956095/sites/dockside-brewing/golf/golf2.jpg",
  "https://res.cloudinary.com/gonation/image/upload/v1689956095/sites/dockside-brewing/golf/golf3.jpg",
  "https://res.cloudinary.com/gonation/image/upload/v1689956095/sites/dockside-brewing/golf/golf4.jpg",
  "https://res.cloudinary.com/gonation/image/upload/v1689956095/sites/dockside-brewing/golf/golf5.jpg",
  "https://res.cloudinary.com/gonation/image/upload/v1689956095/sites/dockside-brewing/golf/golf6.jpg",
]

export default function Golf({ data }) {
  const { getStoryByTag } = useStories()

  const golf1 = getStoryByTag("golf1")

  return (
    <Layout pageTitle="Dockside Golf">
      <Page>
        <AboutContent>
          <AboutText>
            <AboutDescription>
              <TextContent
                title={golf1?.title}
                subtitle={golf1?.subtitle}
                body={golf1?.body}
                sx={{}}
              />
              <MultipleLinksSelector
                links={golf1?.ctaLinks}
                sx={{
                  ".primaryButton": {
                    variant: "buttons.tertiary",
                  },
                }}
              />
            </AboutDescription>
          </AboutText>
          <ImageContainer>
            <AboutImage
              src={cloudinaryString(golf1?.media?.[0].cloudinaryId, 1000)}
            />
          </ImageContainer>
        </AboutContent>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            py: "1rem",
            img: {
              width: ["50%", "", "33.3%"],
              flexGrow: "1",
              p: ["1rem", "2rem"],
            },
          }}
        >
          {golfImages.map(image => {
            return (
              <Image
                src={cloudinaryOptimize(image, 600)}
                alt="golf dockside event"
              />
            )
          })}
        </Box>
      </Page>
    </Layout>
  )
}

const Page = styled.section``

const AboutContent = styled.section`
  padding: 2rem 0;
  background-color: ${props =>
    props.theme.primary ? props.theme.primary : "black"};
  width: 100%;
  @media (min-width: 1024px) {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
`
const AboutText = styled.div`
  color: white;
  padding: 1rem;
  @media (min-width: 1024px) {
    padding-right: 4rem;
    width: 50%;
    padding: 0;
    max-width: 700px;
  }
`

const AboutDescription = styled.div`
  padding: 1.5rem 2rem;
  h2 {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 1.25rem;
  }
  ul,
  li {
    list-style: disc;
  }
  a {
    color: white;
    text-decoration: underline;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    width: 50%;
    max-width: 700px;
  }
`
const AboutImage = styled.img`
  width: 100%;
`

const styles = {
  buttonContainer: {
    display: ["flex"],
    justifyContent: ["center", "", "flex-start"],
    marginTop: "3rem",
    a: {
      background: "rgb(202,54,37)",
      color: "white",
      textTransform: "uppercase",
      WebkitAlignItems: "center",
      WebkitBoxAlign: "center",
      msFlexAlign: "center",
      alignItems: "center",
      fontWeight: 500,
      padding: ["1.25rem 2.5rem", "1.5rem 3.5rem"],
      borderRadius: "4px",
      fontSize: "1rem",
      transition: "all ease-in-out 0.5s",
      ":hover": {
        border: "solid 1px",
        backgroundColor: "transparent",
      },
    },
  },
}

export const query = graphql`
  {
    siteMetaData {
      avatar {
        imageBaseUrl
      }
      city
      desc
      cover {
        imageBaseUrl
      }
      hours {
        fri {
          close
          open
        }
        mon {
          close
          open
        }
        sat {
          close
          open
        }
        sun {
          close
          open
        }
        thu {
          close
          open
        }
        tue {
          close
          open
        }
        wed {
          close
          open
        }
      }
      lastPricelistUpdate {
        sec
        usec
      }
      links {
        facebook
        instagram
        twitter
        youtube
        website
      }
      loc
      slug
      name
      phone
      state
      street
      zip
      bizID
    }
  }
`
